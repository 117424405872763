/*------------------------------------------------------------------
Project:    Nairo Personal Portfolio React Template
Version:    1.0
Primary use:  ib-themes

[Table of contents]

1. variable
2. mixin
3. base
4. color
5. header
6. footer
7. blog
8. portfolio
9. style
10. 404
11. preview
12. animation
13. bootstrap
14. themefy

-------------------------------------------------------------------*/
@import "bootstrap";
@import "et-line-font";
@import "main/variable";
@import "main/mixin";
@import "main/base";
@import "main/button";
@import "main/color";
@import "main/header";
@import "main/footer";
@import "main/blog";
@import "main/portfolio";
@import "main/style";
@import "main/404";
@import "main/preview";
@import "main/animation";
@import "main/slick";
@import "main/modal";
@import "main/rtl";
@import "main/custom-theme-dark";
